import { UUID, Attachment } from '@/types/types';
import { ApiQueryKey } from '@/configs/ApiQueryKey';
import { FormTemplateState, Relation } from '@/store/slices/formTemplateSlice';
import { extractId } from '@/utils/utilities';
import { ProductType } from './ProductTypeApi';
import EntityApi, { BaseEntity } from './EntityApi';
import httpClient from './httpClient';

export enum QuestionType {
	SELECT = 'SELECT',
	MODAL_PICKER = 'MODAL_PICKER',
	TEXT = 'TEXT',
	FILES = 'FILES',
	NUMBER = 'NUMBER',
	TEXTAREA = 'TEXTAREA',
	DIMENSIONS = 'DIMENSIONS',
	ADVANCED_OPTIONS = 'ADVANCED_OPTIONS',
	MODEL_PICKER_TYPE = 'MODEL_PICKER_TYPE',
}

export interface Answer extends BaseEntity {
	question: Question;
	name: string;
	description?: string;
	image?: Attachment;
	listOrder: number;
	minTotalSurface: number;
	relations: Relation[];
}

export interface Question extends BaseEntity {
	questionGroup: QuestionGroup;
	name: string;
	description?: string;
	suffix: string;
	questionType: QuestionType;
	listOrder: number;
	answers: Answer[];
	relations: Relation[];
	placeholder: string;
}

export interface QuestionGroup extends BaseEntity {
	formTemplate: FormTemplate;
	name: string;
	description: string;
	image?: Attachment;
	listOrder: number;
	questions: Question[];
}

export interface FormTemplate extends BaseEntity {
	productType: ProductType;
	questionGroups: QuestionGroup[];
}

class FormTemplateApi extends EntityApi<FormTemplate> {
	questionGroupsQueryKey = 'question-groups';
	quesitonsQueryKey = 'questions';
	answersQueryKey = 'answers';

	constructor() {
		super(ApiQueryKey.formTemplates);
		this.createFormTemplate = this.createFormTemplate.bind(this);
		this.updateFormTemplate = this.updateFormTemplate.bind(this);
	}

	async createFormTemplate(formState: FormTemplateState) {
		const formTemplateResponse = await httpClient.post<FormTemplate>(
				this.queryKey,
				{
					productType: { id: formState.productType },
					questionGroups: [],
				}
			),
			formTemplate = formTemplateResponse.data;

		let questionGroupId: UUID;
		for (questionGroupId in formState.questionGroups) {
			const questionGroupState = formState.questionGroups[questionGroupId];

			const questionGroupResponse = await httpClient.post<QuestionGroup>(
					this.questionGroupsQueryKey,
					{
						formTemplate: extractId(formTemplate),
						name: questionGroupState.name,
						description: questionGroupState.description,
						image: questionGroupState.image,
						listOrder: questionGroupState.listOrder,
						questions: [],
					}
				),
				questionGroup = questionGroupResponse.data;

			let questionId: UUID;
			for (questionId in questionGroupState.questions) {
				const quesitonState = questionGroupState.questions[questionId];

				const questionResponse = await httpClient.post<Question>(
						this.quesitonsQueryKey,
						{
							questionGroup: extractId(questionGroup),
							name: quesitonState.name,
							description: quesitonState.description,
							suffix: quesitonState.suffix,
							questionType: quesitonState.questionType,
							listOrder: quesitonState.listOrder,
							answers: [],
						}
					),
					question = questionResponse.data;

				let answerId: UUID;
				for (answerId in quesitonState.answers) {
					const answerState = quesitonState.answers[answerId];

					await httpClient.post<Answer>(this.answersQueryKey, {
						question: extractId(question),
						name: answerState.name,
						description: answerState.description,
						image: answerState.image,
						listOrder: answerState.listOrder,
						minTotalSurface: answerState.minTotalSurface,
					});
				}
			}
		}

		return 1;
	}

	async updateFormTemplate(formState: FormTemplateState) {
		const { removedEntities } = formState;

		for (const removedQuestionGroup of removedEntities.questionGroups) {
			httpClient.delete(
				`${this.questionGroupsQueryKey}/${removedQuestionGroup}`
			);
		}
		for (const removedQuestion of removedEntities.questions) {
			httpClient.delete(`${this.quesitonsQueryKey}/${removedQuestion}`);
		}
		for (const removedAnswer of removedEntities.answers) {
			httpClient.delete(`${this.answersQueryKey}/${removedAnswer}`);
		}

		const formTemplateResponse = await httpClient.patch<FormTemplate>(
				`${this.queryKey}/${formState.id}`,
				{
					productType: { id: formState.productType },
				}
			),
			formTemplate = formTemplateResponse.data;

		let questionGroupId: UUID;
		for (questionGroupId in formState.questionGroups) {
			const questionGroupState = formState.questionGroups[questionGroupId],
				questionGroupPayload = {
					formTemplate: extractId(formTemplate),
					name: questionGroupState.name,
					description: questionGroupState.description,
					image: questionGroupState.image,
					listOrder: questionGroupState.listOrder,
					questions: questionGroupState.isNew ? [] : undefined,
				};

			const questionGroupResponse = questionGroupState.isNew
					? await httpClient.post<QuestionGroup>(
							this.questionGroupsQueryKey,
							questionGroupPayload
						)
					: await httpClient.patch<QuestionGroup>(
							`${this.questionGroupsQueryKey}/${questionGroupState.id}`,
							questionGroupPayload
						),
				questionGroup = questionGroupResponse.data;

			let questionId: UUID;
			for (questionId in questionGroupState.questions) {
				const quesitonState = questionGroupState.questions[questionId],
					questionPayload = {
						questionGroup: extractId(questionGroup),
						name: quesitonState.name,
						description: quesitonState.description,
						suffix: quesitonState.suffix,
						questionType: quesitonState.questionType,
						listOrder: quesitonState.listOrder,
						placeholder: quesitonState.placeholder,
						answers: quesitonState.isNew ? [] : undefined,
					};

				const questionResponse = quesitonState.isNew
						? await httpClient.post<Question>(
								this.quesitonsQueryKey,
								questionPayload
							)
						: await httpClient.patch<Question>(
								`${this.quesitonsQueryKey}/${quesitonState.id}`,
								questionPayload
							),
					question = questionResponse.data;

				let answerId: UUID;
				for (answerId in quesitonState.answers) {
					const answerState = quesitonState.answers[answerId],
						asnwerPayload = {
							question: extractId(question),
							name: answerState.name,
							description: answerState.description,
							image: answerState.image,
							listOrder: answerState.listOrder,
							minTotalSurface: answerState.minTotalSurface,
						};

					if (answerState.isNew)
						await httpClient.post<Answer>(this.answersQueryKey, asnwerPayload);
					else
						await httpClient.patch<Answer>(
							`${this.answersQueryKey}/${answerState.id}`,
							asnwerPayload
						);
				}
			}
		}

		return 1;
	}
}

export default new FormTemplateApi();
