import { Role } from '@/api/RoleApi';
import { BaseEntity } from '@/api/EntityApi';

export enum UserStatus {
	INACTIVE,
	ACTIVE,
	SUSPENDED,
}

export enum UserType {
	USER = 'user',
	CLIENT = 'client',
	ADMIN = 'admin',
}

interface BaseUser extends BaseEntity {
	email: string;
	password: string;
	status: UserStatus;
	userType: UserType;
}

export interface Client extends BaseUser {
	name: string;
	lastName: string;
	company: string;
	phoneNumber: string;
	userType: UserType.CLIENT;
}

export interface Admin extends BaseUser {
	role: Role;
	userType: UserType.ADMIN;
	name: string;
	lastName: string;
}

export type AppUser = Admin;
