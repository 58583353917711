export const ApiQueryKey = {
	productTypes: 'product-types',

	formTemplates: 'form-templates',
	forms: 'forms',

	clients: 'clients',
	admins: 'admins',
	loginHistory: 'logins-history',

	questions: 'questions',
	answers: 'answers',

	content: 'content',
	roles: 'roles',
	globalSettings: 'global-settings',
} as const;

export type ApiQueryKey = (typeof ApiQueryKey)[keyof typeof ApiQueryKey];
