import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Attachment, UUID } from '@/types/types';
import { StoreKeys } from '@/configs/StoreKeys';
import { FormTemplate, QuestionType } from '@/api/FormTemplateApi';
import APP_CONFIG from '@/configs/appConfig';
import { ProductType } from '@/api/ProductTypeApi';
import { RootState } from '@/store';

export type Relation = {
	answerId?: UUID;
	present: boolean;
};

export interface AnswerState {
	isNew: boolean;
	id: UUID;
	name: string;
	description?: string;
	image?: Attachment;
	listOrder: number;
	minTotalSurface: number;
	relations: Relation[];
}

export interface QuestionState {
	isNew: boolean;
	id: UUID;
	name: string;
	description?: string;
	suffix: string;
	questionType: QuestionType;
	listOrder: number;
	answers: Record<UUID, AnswerState>;
	relations: Relation[];
	placeholder: string;
}

export interface QuestionGroupState {
	isNew: boolean;
	id: UUID;
	name: string;
	description: string;
	image?: Attachment;
	listOrder: number;
	questions: Record<UUID, QuestionState>;
}

export type EditFormTemplateRemovedEntities = {
	questionGroups: UUID[];
	questions: UUID[];
	answers: UUID[];
};

export interface FormTemplateState {
	id: UUID;
	productType: null | UUID;
	productTypeDimensions: null | ProductType['predefinedDimensions'];
	questionGroups: Record<UUID, QuestionGroupState>;
	removedEntities: EditFormTemplateRemovedEntities;
}

const initialState: FormTemplateState = {
	id: crypto.randomUUID(),
	productType: null,
	productTypeDimensions: null,
	questionGroups: {},
	removedEntities: {
		questionGroups: [],
		questions: [],
		answers: [],
	},
};

export const formTemplateSlice = createSlice({
	name: StoreKeys.formTemplate,
	initialState,
	reducers: {
		resetState: (state) => {
			state.productType = null;
			state.removedEntities = {
				questionGroups: [],
				questions: [],
				answers: [],
			};

			const questionGroup = getBlankQuestionGroup();

			state.questionGroups = {
				[questionGroup.id]: questionGroup,
			};
		},
		loadTemplate: (state, { payload }: PayloadAction<FormTemplate>) => {
			state.id = payload.id;
			state.productType = payload.productType.id;
			state.productTypeDimensions = payload.productType.predefinedDimensions;

			state.questionGroups = payload.questionGroups.reduce<
				FormTemplateState['questionGroups']
			>((questionGroupsAcc, questionGroup) => {
				questionGroupsAcc[questionGroup.id] = {
					isNew: false,
					id: questionGroup.id,
					name: questionGroup.name,
					description: questionGroup.description,
					image: questionGroup.image,
					listOrder: questionGroup.listOrder,
					questions: questionGroup.questions.reduce<
						QuestionGroupState['questions']
					>((questionsAcc, question) => {
						questionsAcc[question.id] = {
							isNew: false,
							id: question.id,
							name: question.name,
							description: question.description,
							suffix: question.suffix,
							questionType: question.questionType,
							listOrder: question.listOrder,
							relations: question.relations,
							placeholder: question.placeholder,
							answers: question.answers.reduce<QuestionState['answers']>(
								(answersAcc, answer) => {
									answersAcc[answer.id] = {
										isNew: false,
										id: answer.id,
										name: answer.name,
										description: answer.description,
										image: answer.image,
										listOrder: answer.listOrder,
										minTotalSurface: answer.minTotalSurface,
										relations: answer.relations,
									};

									return answersAcc;
								},
								{}
							),
						};

						return questionsAcc;
					}, {}),
				};

				return questionGroupsAcc;
			}, {});
		},
		handleChange: (
			state,
			{ payload }: PayloadAction<Partial<FormTemplateState>>
		) => {
			Object.assign(state, payload);
		},
		handleAddQuestionGroup: (state) => {
			const questionGroup = getBlankQuestionGroup();

			const highestListOrder = Math.max(
				0,
				...Object.values(state.questionGroups).map(
					(questionGroup) => questionGroup.listOrder
				)
			);
			questionGroup.listOrder = highestListOrder + 1;

			state.questionGroups[questionGroup.id] = questionGroup;
		},
		handleQuestionGroupDelete: (state, { payload }: PayloadAction<UUID>) => {
			if (!state.questionGroups[payload].isNew)
				state.removedEntities.questionGroups.push(payload);

			delete state.questionGroups[payload];
		},
		handleQuestionGroupChange: (
			state,
			{
				payload,
			}: PayloadAction<{ id: UUID; data: Partial<QuestionGroupState> }>
		) => {
			Object.assign(state.questionGroups[payload.id], payload.data);
		},
		handleQuestionChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				data: Partial<QuestionState>;
			}>
		) => {
			const question =
				state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				];

			Object.assign(question, payload.data);

			if (
				payload.data.questionType &&
				APP_CONFIG.NON_ANSWER_QUESTION_TYPES.includes(payload.data.questionType)
			)
				question.answers = {};
		},
		handleAddQuestion: (state, { payload }: PayloadAction<UUID>) => {
			const question = getBlankQuestion();

			const highestListOrder = Math.max(
				0,
				...Object.values(state.questionGroups[payload].questions).map(
					(question) => question.listOrder
				)
			);
			question.listOrder = highestListOrder + 1;

			Object.assign(state.questionGroups[payload].questions, {
				[question.id]: question,
			});
		},
		handleQuestionDelete: (
			state,
			{ payload }: PayloadAction<{ questionGroupId: UUID; questionId: UUID }>
		) => {
			if (
				!state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].isNew
			)
				state.removedEntities.questions.push(payload.questionId);

			delete state.questionGroups[payload.questionGroupId].questions[
				payload.questionId
			];
		},
		handleAddAnswer: (
			state,
			{ payload }: PayloadAction<{ questionGroupId: UUID; questionId: UUID }>
		) => {
			const answer = getBlankAnswer();

			const highestListOrder = Math.max(
				0,
				...Object.values(
					state.questionGroups[payload.questionGroupId].questions[
						payload.questionId
					].answers
				).map((answer) => answer.listOrder)
			);
			answer.listOrder = highestListOrder + 1;

			Object.assign(
				state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].answers,
				{
					[answer.id]: answer,
				}
			);
		},
		handleAnswerDelete: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
			}>
		) => {
			if (
				!state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].answers[payload.answerId].isNew
			)
				state.removedEntities.answers.push(payload.answerId);

			delete state.questionGroups[payload.questionGroupId].questions[
				payload.questionId
			].answers[payload.answerId];
		},
		handleAnswerChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
				data: Partial<AnswerState>;
			}>
		) => {
			Object.assign(
				state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].answers[payload.answerId],
				payload.data
			);
		},
		handleRelationToQuestionAdd: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
			}>
		) => {
			const { questionGroupId, questionId } = payload;
			const relation = getBlankRelation();
			if (
				state.questionGroups[questionGroupId].questions[questionId]
					.relations === undefined
			) {
				state.questionGroups[questionGroupId].questions[questionId].relations =
					[];
			}
			state.questionGroups[questionGroupId].questions[
				questionId
			].relations.push(relation);
		},
		handleRelationToAnswerAdd: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
			}>
		) => {
			const emptyRelation = getBlankRelation();
			const { questionGroupId, questionId, answerId } = payload;
			if (
				state.questionGroups[questionGroupId].questions[questionId].answers[
					answerId
				].relations === undefined
			) {
				state.questionGroups[questionGroupId].questions[questionId].answers[
					answerId
				].relations = [];
			}

			state.questionGroups[questionGroupId].questions[questionId].answers[
				answerId
			].relations.push(emptyRelation);
		},
		handleRelationToProductDimensionsAdd: (
			state,
			{
				payload,
			}: PayloadAction<{
				index: number;
			}>
		) => {
			const emptyRelation = getBlankRelation();

			const { index } = payload;
			if (state.productTypeDimensions) {
				if (state.productTypeDimensions[index].relations === undefined) {
					state.productTypeDimensions[index].relations = [];
				}

				state.productTypeDimensions[index].relations.push(emptyRelation);
			}
		},
		handleRelationToQuestionRemove: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				relationIndex: number;
			}>
		) => {
			const { questionGroupId, questionId, relationIndex } = payload;

			state.questionGroups[questionGroupId].questions[
				questionId
			].relations.splice(relationIndex, 1);
		},

		handleRelationToAnswerRemove: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
				relationIndex: number;
			}>
		) => {
			const { questionGroupId, questionId, answerId, relationIndex } = payload;

			state.questionGroups[questionGroupId].questions[questionId].answers[
				answerId
			].relations.splice(relationIndex, 1);
		},
		handleRelationToProductDimensionRemove: (
			state,
			{
				payload,
			}: PayloadAction<{
				index: number;
				relationIndex: number;
			}>
		) => {
			const { index, relationIndex } = payload;

			if (
				state.productTypeDimensions &&
				state.productTypeDimensions[index].relations
			) {
				state.productTypeDimensions[index].relations.splice(relationIndex, 1);
			}
		},
		handleRelationToQuestionChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				relationIndex: number;
				data: Partial<Relation>;
			}>
		) => {
			const { questionGroupId, questionId, relationIndex, data } = payload;

			const relation =
				state.questionGroups[questionGroupId].questions[questionId].relations[
					relationIndex
				];

			Object.assign(relation, data);
		},
		handleRelationToAnswerChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
				relationIndex: number;
				data: Partial<Relation>;
			}>
		) => {
			const { questionGroupId, questionId, answerId, relationIndex, data } =
				payload;

			const relation =
				state.questionGroups[questionGroupId].questions[questionId].answers[
					answerId
				].relations[relationIndex];

			Object.assign(relation, data);
		},
		handleRelationToProductDimensionChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				index: number;
				relationIndex: number;
				data: Partial<Relation>;
			}>
		) => {
			const { index, relationIndex, data } = payload;

			if (
				state.productTypeDimensions &&
				state.productTypeDimensions[index].relations
			) {
				const relation =
					state.productTypeDimensions[index].relations[relationIndex];

				Object.assign(relation, data);
			}
		},
	},
});

export const formActions = formTemplateSlice.actions;

export default formTemplateSlice.reducer;

function getBlankQuestionGroup(): QuestionGroupState {
	const question = getBlankQuestion();

	return {
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		description: '',
		listOrder: 1,
		questions: {
			[question.id]: question,
		},
	};
}

function getBlankQuestion(): QuestionState {
	const answer = getBlankAnswer();

	return {
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		description: '',
		suffix: '',
		placeholder: '',
		questionType: QuestionType.SELECT,
		listOrder: 1,
		relations: [],
		answers: {
			[answer.id]: answer,
		},
	};
}

function getBlankAnswer(): AnswerState {
	return {
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		description: '',
		listOrder: 1,
		minTotalSurface: 0,
		relations: [],
	};
}

function getBlankRelation(): Relation {
	return {
		present: false,
	};
}

export const getFormTemplateState = (state: RootState) => state.formTemplate;

export const getFormTemplateQuestionGroups = createSelector(
	[getFormTemplateState],
	(state) =>
		Object.values(state.questionGroups).sort(
			(a, b) => a.listOrder - b.listOrder
		)
);

export const getSelectDataAnswers = createSelector(
	[getFormTemplateQuestionGroups],
	(questionGroups) =>
		questionGroups
			.flatMap((group) => {
				return Object.values(group.questions)
					.toSorted((a, b) => a.listOrder - b.listOrder)
					.map((question) => ({
						group: `${group.name} > ${question.name}`,
						items: question.answers
							? Object.values(question.answers).map((answer) => ({
									value: answer.id,
									label: answer.name,
								}))
							: [],
					}));
			})
			.filter((item) => item.items.length > 0)
);
