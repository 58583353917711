import { flatten, pick } from 'lodash';
import { isAxiosError } from 'axios';
import { ApiError, AppError, ErrorCode } from '@/types/ErrorCodes';
import { formatDistance } from 'date-fns';
import { BaseEntity } from '@/api/EntityApi';
import { Language } from '@/types/types';
import {
	PredefinedDimension,
	ProductType,
	SectionType,
} from '@/api/ProductTypeApi';
import { pl } from 'date-fns/locale';
import { QuestionType } from '@/api/FormTemplateApi';
import { Permissions } from '@/api/RoleApi';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import { FormStatus } from '@/api/FormApi';
import { UserStatus } from '@/types/users';

export const priceFormatter = new Intl.NumberFormat('PL-pl', {
	style: 'currency',
	currency: 'PLN',
	currencyDisplay: 'code',
});

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

export function randomDate(start: Date, end: Date) {
	return moment(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	).toDate();
}

export function formatDateForApi(date: moment.MomentInput) {
	return moment(date).format('YYYY-MM-DD');
}

export function formatDateForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('HH:mm');
}

export function sortByDate(
	dateA: moment.MomentInput,
	dateB: moment.MomentInput,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function sortByName(
	nameA = '',
	nameB = '',
	order: 'asc' | 'desc' = 'asc'
) {
	return order === 'asc'
		? nameA.localeCompare(nameB)
		: nameB.localeCompare(nameA);
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function decrementDate(date: moment.MomentInput) {
	return moment(date).subtract(1, 'day').toDate();
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export function priceToFixed(price: number) {
	return price.toFixed(2);
}

export const formatPrice = (value = 0, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export const isAppError = (error: unknown): error is AppError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as AppError).message
	);
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export const fontColorForBackground = (backgroundColorHex = '#ffffff') => {
	return [
		0.299 * parseInt(backgroundColorHex.slice(1, 3), 16),
		0.587 * parseInt(backgroundColorHex.slice(3, 5), 16),
		0.114 * parseInt(backgroundColorHex.slice(5, 7), 16),
	].reduce((a, b) => a + b) /
		255 >
		0.65
		? '#495057'
		: '#ffffff';
};

export const formatFullName = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) return `${firstName} ${lastName}`;
	if (!firstName && !lastName) return `-`;
	return firstName || lastName;
};

export function formatDateFromNow(date: Date) {
	return formatDistance(date, new Date(), {
		locale: pl,
		includeSeconds: true,
		addSuffix: true,
	});
}

export function isDefaultLanguage(language: Language): language is 'PL' {
	return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function fileSizeToMB(size: number | string) {
	return Math.round((Number(size) / 1_000_000 + Number.EPSILON) * 100) / 100;
}

export function extractId<TData extends BaseEntity>(entity: TData) {
	return pick(entity, 'id');
}

export function mapQuestionTypesToNames(type: QuestionType) {
	switch (type) {
		case QuestionType.SELECT:
			return 'Select';
		case QuestionType.FILES:
			return 'Pliki';
		case QuestionType.MODAL_PICKER:
			return 'Modal';
		case QuestionType.NUMBER:
			return 'Liczba';
		case QuestionType.TEXT:
			return 'Tekst';
		case QuestionType.TEXTAREA:
			return 'Textarea';
		case QuestionType.DIMENSIONS:
			return 'Wymiary';
		case QuestionType.ADVANCED_OPTIONS:
			return 'Zaawansowane opcje';
		case QuestionType.MODEL_PICKER_TYPE:
			return 'Typ modelu';
	}
}

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function formatExactDate(date: moment.MomentInput = new Date()) {
	return `${formatDateForPresentation(date)}, ${formatTimeForPresentation(
		date
	)}`;
}

export function blankProductTypeContent(): ProductType['content'] {
	return {
		[SectionType.CARDS]: {
			title: '',
			description: '',
			cards: [],
		},
		[SectionType.LISTS]: {
			title: '',
			description: '',
			lists: [],
		},
		[SectionType.ARTICLES]: {
			title: '',
			description: '',
			articles: [],
		},
	};
}

export function formatPermisionName(section: keyof Permissions) {
	switch (section) {
		case 'admins':
			return 'Admini';
		case 'roles':
			return 'Grupy dostępu';
		case 'formTemplates':
			return 'Szablony formularzy';
		case 'globalSettings':
			return 'Ustawienia globalne';
		case 'productTypes':
			return 'Typy produktów';
		case 'forms':
			return 'Zapytania ofertowe';
	}
}

export function formatUserStatusName(userStatus: UserStatus) {
	switch (userStatus) {
		case UserStatus.ACTIVE:
			return 'Aktywne';
	}
}

export function formatFormStatus(status: FormStatus) {
	const data = { color: '', name: '' };

	switch (status) {
		case FormStatus.NEW_FORM:
			data.color = 'warning';
			data.name = 'Nowe zapytanie';
			break;
		case FormStatus.IN_VALUATION:
			data.color = 'info';
			data.name = 'W trakcie wyceny';
			break;
		case FormStatus.IN_PRODUCTION:
			data.color = 'primary';
			data.name = 'W produkcji';
			break;
		case FormStatus.COMPLETED:
			data.color = 'success';
			data.name = 'Zrealizowane';
			break;
	}

	return data;
}

export const getDimensionTitle = (dimension: PredefinedDimension) => {
	return `${dimension.height}mm x ${dimension.width}mm x ${dimension.depth}mm`;
};
