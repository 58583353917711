export enum AppPaths {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	FORM_TEMPLATES = 'form-templates',
	FORMS = 'forms',

	PRODUCT_TYPES = 'product-types',

	CONTENT = 'content',

	ADMINS = 'admins',
	CLIENTS = 'clients',
	LOGIN_HISTORY = 'login-history',
	ROLES = 'roles',

	GLOBAL_SETTINGS = 'global-settings',
}
